@use 'reset';
@use '_variables.scss' as *;
@use '_mixins.scss' as *;

/* Main css */
html {
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: var(--base-100);
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: auto;
  // No reload on up scroll
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
  -webkit-touch-callout: none;
  caret-color: var(--accent-500);
  accent-color: var(--accent-500);
}

*:focus {
  outline: none;
}

*,
body {
  font-family: Poppins, Quicksand, sans-serif;
  font-size: 11px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  color: var(--base-200);
  background: linear-gradient(165deg, var(--accent-500), var(--accent-400) 150%);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: var(--base-100);
  text-wrap: balance;
}

h1 {
  color: var(--base-200);
  letter-spacing: var(--letter-spacing-title);
  @include font(24, 1.2);
}

h2 {
  color: var(--base-100);
  letter-spacing: var(--letter-spacing-title);
  @include font(18, 1.2);
}

h3 {
  letter-spacing: var(--letter-spacing-title);
  @include font(15, 1.2);
}

h4 {
  letter-spacing: var(--letter-spacing-text);
  @include font(13, 1.3);
}

p {
  letter-spacing: var(--letter-spacing-text);
  @include font(13, 1.3);
}

button {
  font-weight: 400;
  align-items: center;
  justify-content: center;
  background: none;
  padding: 14px 16px;
  border-radius: 16px;
  border: var(--border-size) solid transparent;
  color: var(--base-100);
  letter-spacing: var(--letter-spacing-text);
  cursor: pointer;
  @include font(13, 1.3);
}

input {
  border: none;
  background: none;
}

cdk-virtual-scroll-viewport {
  height: 100%;
}

.blur {
  @include blur();
}

.button-accent {
  color: var(--base-900);
  font-weight: 600;
  background: linear-gradient(
    135deg,
    hsla(var(--accent-600-string), 0.9),
    hsla(var(--accent-500-string), 0.9),
    var(--accent-600),
    var(--accent-500)
  );
  background-size: 300% 300%;
  box-shadow: 0 4px 12px -3px hsla(var(--accent-500-string), 40%);
  border: var(--border-size) solid var(--accent-600);
  padding: 12px;
  border-radius: 18px;
  @include strongTextShadow();
  @include transitionOut();

  &[disabled] {
    color: var(--base-200);
    background: var(--base-500);
    box-shadow: 0 4px 12px -3px hsla(var(--base-400-string), 40%);
    border: var(--border-size) solid var(--base-500);
  }

  &:hover {
    color: var(--base-800);
    background-position: 99%;
    box-shadow: 0 4px 12px -3px hsla(var(--accent-500-string), 60%);
    @include transitionIn();
  }

  &.animation {
    box-shadow: 0 2px 18px -8px hsla(var(--accent-700-string), 40%);
    border-color: var(--accent-700);
    background-position: 99%;
    will-change: box-shadow, border-color, background-position;
    @include transitionIn('box-shadow, border-color, background-position');

    span {
      color: var(--base-800);
      text-shadow: 0 1px 3px hsla(var(--base-100-string), 10%);
      will-change: color, text-shadow;
      @include transitionIn('color, text-shadow');
    }
  }
}

.app-page {
  display: grid;
  grid-template-rows: 30px auto;
  gap: 16px;
  height: 100%;
  width: 100%;
  padding-top: var(--page-padding-vertical);
  overflow-y: hidden;

  &__title {
    text-align: center;
    font-weight: 500;
    padding-top: 3px;
    color: var(--base-100);
    letter-spacing: var(--letter-spacing-title);
    @include font(19, 1.2);
    @include textShadow();
    @extend .non-selectable;

    + div {
      padding: 0 var(--page-padding-horizontal);
    }
  }
}

.app-page.page-with-action {
  padding-bottom: 64px;
}

.discount {
  position: absolute;
  right: 8px;
  bottom: 8px;
  border-radius: 8px;
  padding: 2px 8px;
  background: hsla(var(--success-300-string), 50%);
  border: var(--border-size) solid hsla(var(--success-300-string), 80%);
  color: var(--base-800);
  font-weight: 600;
  box-shadow: 0 2px 8px -2px hsla(var(--success-300-string), 50%);
  @include font(12, 1.3);
  @include strongTextShadow();
  @include contrast-blur();
}

.non-selectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
}

.ant-notification-notice {
  padding: 16px;
  background: var(--base-900);
  border: var(--border-size) solid white;
  box-shadow: 0 12px 48px -24px hsla(var(--base-100-string), 60%);
  background: hsla(var(--base-900-string), 85%);
  @include strong-blur();
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 40px;
  font-weight: 500;
  color: var(--base-400);
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 40px;
  font-weight: 400;
  color: var(--base-300);
  padding-right: 32px;
}

.ant-notification-notice-icon {
  margin-top: 4px;
  margin-left: 0px;
}

.ant-notification-close-icon {
  color: var(--base-200);
}

.ant-notification-notice-content {
  svg {
    @include iconFont(14);
  }
}

.medium-weight {
  font-weight: 600;
  color: var(--base-100);
}

.decimals {
  margin-left: -0.4em;
  @include font(9, 1.4);
}

.transparent {
  opacity: 50%;
}

.clickable {
  transform: translate3d(0, 0, 0);
  cursor: pointer;
}

.accordion-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  opacity: 0%;
  will-change: grid-template-rows, opacity;
  @include transitionOut('grid-template-rows, opacity');

  &.expanded {
    grid-template-rows: 1fr;
    opacity: 100%;
    will-change: grid-template-rows;
    @include transitionIn('grid-template-rows, opacity');
  }

  > * {
    overflow: hidden;
  }
}

.breadcrumb {
  color: var(--base-200);

  &:hover {
    color: var(--accent-500);
  }
}

.divider {
  margin: 8px auto 24px auto;
  border-top: var(--border-size) solid var(--base-600);
  will-change: visibility, opacity, transform;
  @include transitionOut('visibility, opacity, transform');
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--accent-700) var(--base-700);
}

input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: var(--base-700) !important;
  -webkit-box-shadow: 0 0 0 50px var(--base-700) inset;
  -webkit-text-fill-color: var(--base-100) !important;
  @include transitionIn();
}

.ant-input-affix-wrapper > input.ant-input {
  background: none;
  border-radius: 0;
}

.ant-input-number-input {
  padding: 0 16px;
  border-radius: 12px;
  background-color: var(--base-700);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--base-900);
}

.ant-input {
  background-color: var(--base-700);
  color: var(--base-200);
  border: var(--border-size) solid var(--base-800);
  font-weight: normal;
  @include font(11, 1.3);

  &:hover {
    background-color: var(--base-800);
    border-color: var(--base-900);
  }

  &-affix-wrapper {
    color: var(--base-200);
    background-color: var(--base-700);
    border-color: var(--base-800);
  }

  &-number {
    font-weight: normal;
    border: var(--border-size) solid var(--base-800);
    @include font(11, 1.3);

    &-input {
      background-color: var(--base-700);
      border: 0;
      font-weight: normal;
    }
  }
}

.ant-input:focus,
.ant-input-focused {
  background-color: var(--base-800);
  border-color: var(--base-900);
}

.ant-input {
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--base-500);
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--base-500);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--base-500);
  }
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: var(--base-900);
}

.ant-select-item {
  font-size: 11px;
  color: var(--base-200);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: var(--base-100);
  background-color: var(--base-900);
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--base-900);
}

.ant-form-item-explain-error {
  margin-top: 2px;
}

.ant-form label,
label {
  color: var(--base-100);
  font-weight: 600;
  @include font(12, 1.3);
}

.ant-form-item-label > label .ant-form-item-tooltip {
  color: var(--accent-700);
}

.ant-modal-title {
  font-weight: 600;
}

.ant-steps-item-icon {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: var(--accent-500);
  border: var(--border-size) solid var(--accent-600);
  box-shadow: 0 4px 12px -3px hsla(var(--accent-500-string), 40%);
  color: var(--base-900);
}

.ant-steps-item-wait .ant-steps-item-icon {
  background: none;
  border: var(--border-size) solid var(--base-500);
  color: var(--base-500);
}

.ant-steps-item-finish .ant-steps-item-icon {
  background: none;
  border: var(--border-size) solid var(--base-200);
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--base-200);
}

.ant-steps-item-icon .ant-steps-icon {
  font-weight: 600;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  font-size: 14px;
  color: var(--base-100);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-title);
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  font-size: 14px;
  color: var(--base-400);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-title);
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  font-size: 11px;
  color: var(--base-400);
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  font-size: 11px;
  color: var(--base-200);
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  font-size: 14px;
  color: var(--base-200);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-title);
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  font-size: 11px;
  color: var(--base-200);
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: var(--base-500);
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: var(--base-200);
}

.ant-form-item-explain,
.ant-form-item-extra {
  margin-top: 2px;
  color: var(--base-100);
  @include font(11, 1.3);
}

.ant-btn {
  box-shadow: none;
}

.ant-tabs-tab {
  font-size: 12px;
  padding: 8px 12px;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 12px;
}

.ant-tabs-tab-btn {
  color: var(--base-200);
  font-weight: 500;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--accent-100);
  font-weight: 500;
  text-shadow: none;
}

.ant-btn[nztype='default'] {
  color: var(--base-200);
  background: none;
  border-color: var(--base-500);
  border-radius: 12px;
  @include transitionOut('color, border-color');

  &:hover,
  &:focus,
  &:active {
    color: var(--base-200);
    background: none;
    border-color: var(--base-400);
    @include transitionIn('color, border-color');
  }
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  border-color: var(--base-500);
  color: var(--base-400);
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  color: var(--base-400);
  background: transparent;
}

.ant-btn-primary {
  color: var(--base-900);
  border-color: var(--accent-600);
  background: var(--accent-500);
  border-radius: 12px;
  @include transitionOut('background, color, border-color');

  &:hover,
  &:focus,
  &:active {
    color: var(--base-900);
    border-color: var(--accent-700);
    background: var(--accent-600);
    @include transitionIn('background, color, border-color');
  }
}

.ant-pagination-item {
  border-radius: 12px;
  @include transitionOut('color, border');

  &:hover {
    @include transitionIn('color, border');
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 12px;
  padding: 0px 16px;
}

.ant-pagination-item a {
  @include transitionOut('font-weight, background, color, border');
}

.ant-pagination-item-active a {
  border-radius: 12px;
  font-weight: 600;
  background: var(--base-700);
  @include transitionIn('font-weight, background, color, border');
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before,
.anticon-question-circle {
  color: var(--accent-200);
}

.ant-modal-mask {
  background-color: hsla(var(--base-100-string), 50%);
}

.ant-modal-content {
  box-shadow: 0 0 48px -24px hsla(var(--base-100-string), 45%);
  border: var(--border-size) solid var(--base-900);
}

.ant-modal-header {
  border-bottom-color: var(--base-600);
}

.ant-modal-footer {
  border-top-color: var(--base-600);
}

.ant-notification-notice-description:first-letter {
  text-transform: uppercase;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 24px 0;
}

.ant-upload.ant-upload-drag p.ant-upload-text {
  color: var(--base-200);
  font-weight: 600;
  letter-spacing: var(--letter-spacing-title);
  margin: 0;
  @include font(14, 1.3);
}

.ant-upload.ant-upload-drag p.ant-upload-hint {
  @include font(11, 1.3);
}

.ant-upload.ant-upload-drag {
  background: var(--base-700);
  border: var(--border-size) solid var(--base-800);
  @include transitionOut('background, border');
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  background: var(--base-800);
  border: var(--border-size) solid var(--base-900);
  @include transitionIn('background, border');
}

.ant-timeline-item {
  @include font(11, 1.3);

  &-content {
    padding-top: 4px;
  }

  &-head {
    background: none;
    border: 2px solid var(--accent-600);

    &-red {
      border-color: var(--accent-600);
    }

    &-gray,
    &-grey {
      border-color: var(--base-500);
    }
  }
}

.ant-timeline-item-tail {
  border-left: 2px solid var(--base-500);
}

.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: auto;
}

.timeline {
  &__title {
    color: var(--base-200);
    font-weight: 400;
    @include font(11, 1.3);

    &.old {
      color: var(--base-300);
    }
  }

  &__description {
    color: var(--base-200);
    font-weight: 300;
    @include font(11, 1.3);

    &.old {
      color: var(--base-300);
    }
  }
}

.ant-select-multiple .ant-select-selection-item {
  border-color: var(--accent-700);
  background: var(--accent-700);
  color: var(--base-800);
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  background: var(--base-500);
  color: var(--base-100);
  border: none;
  font-size: 9px;
  border-radius: 8px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: var(--base-700);
}

.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--base-800);
}

.ant-select-multiple .ant-select-selection-item-remove {
  align-self: center;
}

.ant-select-multiple .ant-select-selection-item-remove svg {
  fill: var(--base-800);
  @include font(9, 1.4);
}

.ant-table-wrapper {
  height: 100%;
  overflow: hidden;
  border-radius: 24px;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .ant-table {
        max-height: 90%;
        overflow: auto;

        &-body {
          overflow-y: hidden !important;
        }
      }
    }
  }
}

.ant-table-header,
.nz-table-hide-scrollbar {
  overflow: hidden !important;
}

.ant-table-filter-dropdown-btns {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top: var(--border-size) solid var(--base-700);
}

.ant-table-pagination.ant-pagination {
  margin: 24px;
}

.ant-table-filter-dropdown {
  background: var(--base-900);
  border: var(--border-size) solid white;
  box-shadow: 0 24px 48px -24px hsla(var(--base-200-string), 40%);
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background: var(--base-900);
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  background: white;
}

.ant-dropdown-menu {
  background: var(--base-900);
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 16px 24px;
}

.selected-row {
  background-color: var(--base-900);
  color: var(--base-100);
  font-weight: 500;
  @include transitionIn('background-color, color, font-weight');
}

.ant-table {
  color: var(--base-200);
  @include transitionOut('background-color, color, font-weight');

  &-title {
    padding: 24px 24px 8px 24px;
  }

  &-thead {
    tr > th {
      color: var(--base-200);
      background: none;
      padding: 16px 24px;
    }

    th.ant-table-column-sort {
      background: none;
    }
  }

  &-tbody > {
    tr > td {
      border-color: var(--base-700);
      padding: 16px 24px;
    }

    tr:last-child > td {
      border-bottom-left-radius: 32px;
      border-bottom-right-radius: 32px;
    }

    tr.ant-table-row {
      &:nth-last-child(2) {
        > td {
          border-bottom: 0;

          &:first-child {
            border-bottom-left-radius: 0;
          }

          &:last-child {
            border-bottom-right-radius: 0;
          }
        }

        &:hover td {
          &:first-child {
            border-bottom-left-radius: 0;
          }

          &:last-child {
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }

  &-tfoot {
    tr > th {
      padding: 16px 24px;
    }

    tr > td {
      padding: 16px 24px;
    }
  }

  table {
    border-radius: 32px;
  }

  &-container {
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;

    table > thead > {
      tr:first-child {
        th:first-child {
          border-top-left-radius: 32px;
        }

        th:last-child {
          border-top-right-radius: 32px;
        }
      }
    }
  }
}

.ant-input-affix-wrapper-disabled {
  background-color: transparent;
  border: var(--border-size) solid var(--base-500);
}

.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
  border: var(--border-size) solid var(--base-500);
  padding: 0 6px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-size: 10px;
}

.ant-form-item-label {
  text-align: start;
}

.ant-input {
  font-size: 10px;
}

.ant-input[disabled] {
  color: var(--base-400);
}

.ant-input-affix-wrapper:hover {
  border-color: var(--base-900);
  background: var(--base-800);
}

.ant-input-affix-wrapper-disabled:hover {
  border-color: var(--base-500);
  background: transparent;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--base-900);
  background: var(--base-800);
}

.ant-input-number {
  width: auto;
  border-radius: 12px;
}

.ant-input-number:hover {
  border-color: var(--base-900);

  .ant-input-number-input {
    background-color: var(--base-800);
  }
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 24px 0;
}

.ant-badge {
  align-self: center;

  &-count {
    background: var(--base-700);
    color: var(--accent-200);
    border: var(--border-size) solid var(--base-800);
    box-shadow: 0 4px 12px -3px hsla(var(--base-700-string), 40%);
    font-weight: 600;
    @include font(10, 1.4);

    &-sm {
      border-radius: 5px;
      padding: 0px 2px;
    }
  }
}

.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  color: var(--accent-200);
  @include font(10, 1.4);
  @include textShadow();
}

.ant-switch {
  background: var(--base-500);
  border: var(--border-size) solid var(--base-500);

  &-handle {
    left: 4px;
    width: 16px;
    height: 16px;
  }

  &-checked {
    background: var(--accent-700);
    border: var(--border-size) solid var(--accent-700);
  }

  &-handle::before {
    background-color: var(--base-700);
    border: var(--border-size) solid var(--base-800);
    box-shadow: 0 4px 12px -4px hsla(var(--base-100-string), 40%);
  }
}

.ant-skeleton-element {
  .ant-skeleton-image {
    width: 100%;
    height: 100%;
    border-radius: inherit;

    &-svg {
      width: 50%;
      height: auto;
    }
  }
}

/* Hide scrollbar for IE, Edge add Firefox on mobile */
@media all and (max-width: 768px) {
  .no-scrollbar {
    scrollbar-color: transparent transparent;
  }
  .no-scrollbar::-webkit-scrollbar {
    height: 0px;
  }
  .no-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .no-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .no-scrollbar::-webkit-scrollbar-thumb:window-inactive {
    background-color: rgba(0, 0, 0, 0);
  }
}
