@use 'src/styles/_variables.scss' as *;

// Transitions
@keyframes fadein {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

// Mixins
@mixin accent-gradient100() {
  background: linear-gradient(40deg, var(--accent-600), var(--accent-700));
}

@mixin accent-gradient200() {
  background: linear-gradient(40deg, var(--accent-600), var(--accent-700));
}

@mixin blur() {
  backdrop-filter: blur(6px);
}

@mixin contrast-blur() {
  backdrop-filter: blur(6px) brightness(80%) contrast(80%) saturate(120%);
}

@mixin strong-blur() {
  backdrop-filter: blur(12px);
}

@mixin textShadow() {
  text-shadow: 0 1px 3px hsla(var(--base-200-string), 15%);
}

@mixin strongTextShadow() {
  text-shadow: 0 1px 3px hsla(var(--base-200-string), 40%);
}

@mixin font-size($fontSizeValue: 11) {
  font-size: $fontSizeValue + px;
  // font-size: round(0.0625 * $fontSizeValue) + rem;
}

@mixin line-height($fontSizeValue, $multiplier: 1.2) {
  line-height: round($fontSizeValue * $multiplier) + px;
  // line-height: round(0.0625 * $fontSizeValue * $multiplier) + rem;
}

@mixin font($sizeValue: 11, $multiplier: 1.2) {
  @include font-size($sizeValue);
  @include line-height($sizeValue, $multiplier);
}

@mixin iconFont($sizeValue: 8) {
  @include font($sizeValue, 1);
}

@mixin transitionIn($properties: 'all', $delayMilliseconds: 'initial') {
  -webkit-transition-property: #{$properties};
  -webkit-transition-duration: var(--transition-in-seconds);
  -webkit-transition-timing-function: var(--cubic-bezier);
  -webkit-transition-delay: #{$delayMilliseconds};
  transition-property: #{$properties};
  transition-duration: var(--transition-in-seconds);
  transition-timing-function: var(--cubic-bezier);
  transition-delay: #{$delayMilliseconds};
}

@mixin transitionOut($properties: 'all', $delayMilliseconds: 'initial') {
  -webkit-transition-property: #{$properties};
  -webkit-transition-duration: var(--transition-out-seconds);
  -webkit-transition-timing-function: var(--cubic-bezier);
  -webkit-transition-delay: #{$delayMilliseconds};
  transition-property: #{$properties};
  transition-duration: var(--transition-out-seconds);
  transition-timing-function: var(--cubic-bezier);
  transition-delay: #{$delayMilliseconds};
}

@mixin transitionInSlow($properties: 'all', $delayMilliseconds: 'initial') {
  -webkit-transition-property: #{$properties};
  -webkit-transition-duration: var(--transition-in-slow-seconds);
  -webkit-transition-timing-function: var(--cubic-bezier);
  -webkit-transition-delay: #{$delayMilliseconds};
  transition-property: #{$properties};
  transition-duration: var(--transition-in-slow-seconds);
  transition-timing-function: var(--cubic-bezier);
  transition-delay: #{$delayMilliseconds};
}

@mixin transitionOutSlow($properties: 'all', $delayMilliseconds: 'initial') {
  -webkit-transition-property: #{$properties};
  -webkit-transition-duration: var(--transition-out-slow-seconds);
  -webkit-transition-timing-function: var(--cubic-bezier);
  -webkit-transition-delay: #{$delayMilliseconds};
  transition-property: #{$properties};
  transition-duration: var(--transition-out-slow-seconds);
  transition-timing-function: var(--cubic-bezier);
  transition-delay: #{$delayMilliseconds};
}

@mixin gpuRenderStyles() {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  -ms-perspective: 1000px;
  perspective: 1000px;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@mixin gradientBorder($darkColor, $lightColor) {
  background: linear-gradient(30deg, $darkColor 0%, $lightColor 60%);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
