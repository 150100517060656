// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import '../../node_modules/ng-zorro-antd/ng-zorro-antd.less';

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less
@base-900: hsl(210, 17%, 98%);
@base-800: hsl(210, 16%, 93%);
@base-700: hsl(210, 14%, 89%);
@base-600: hsl(210, 16%, 85%);
@base-500: hsl(210, 14%, 77%);
@base-400: hsl(210, 11%, 68%);
@base-300: hsl(210, 8%, 56%);
@base-200: hsl(210, 7%, 46%);
@base-100: hsl(210, 9%, 31%);
@accent-900: hsl(22, 56%, 91%);
@accent-800: hsl(21, 57%, 85%);
@accent-700: hsl(22, 57%, 77%);
@accent-600: hsl(22, 57%, 71%);
@accent-500: hsl(22, 57%, 64%);
@accent-400: hsl(22, 52%, 61%);
@accent-300: hsl(22, 41%, 56%);
@accent-200: hsl(22, 33%, 48%);
@accent-100: hsl(22, 37%, 37%);
@warning: hsl(52, 57%, 64%);
@error: hsl(352, 57%, 64%);
@success: hsl(142, 57%, 64%);

// Main Colors
@primary-color: @accent-500;
@info-color: @base-300;
@success-color: @success;
@warning-color: @warning;
@error-color: @error;
@normal-color: @base-300;
@highlight-color: @accent-700;
@white: @base-900;
@body-background: @base-800;
@component-background: @base-800;
@black: @base-200;

// Other Styles
@border-radius-base: 16px;
@font-family: 'Poppins', 'Quicksand', sans-serif;
@font-size-base: 13px;
@text-color: @base-300;
@text-color-secondary: @base-400;
@icon-color-hover: @base-400;
@heading-color: @base-400;
@text-color-dark: @base-600;
@text-color-secondary-dark: @base-500;
@item-active-bg: @accent-500;
@item-hover-bg: @base-800;
@border-color-base: @base-800;
@border-color-split: @base-500;
@border-color-inverse: @base-400;
@height-base: 36px;
@height-lg: 40px;
@height-sm: 32px;

// Components
@dropdown-selected-color: @primary-color;
@dropdown-menu-submenu-disabled-bg: @component-background;
@dropdown-selected-bg: @primary-color;

// Select
// ---
@select-border-color: @border-color-base;
@select-item-selected-color: @text-color;
@select-item-selected-bg: transparent;
@select-background: @component-background;
@select-multiple-item-base-500: @base-500;

// Input
// ---
@input-placeholder-color: hsv(0, 0, 75%);
@input-icon-color: @primary-color;
@input-icon-hover-color: @accent-700;
@input-border-color: @base-500;
@input-bg: @base-900;
@input-hover-border-color: @primary-color;
@input-addon-bg: @background-color-light;
@input-base-500: @base-500;

// Modal
// --
@modal-header-title-font-size: @font-size-base + 4px;

// Buttons
@btn-font-weight: 400;

@btn-primary-color: @base-800;
@btn-primary-bg: @primary-color;

@btn-default-color: @base-700;
@btn-default-bg: @base-500;
@btn-default-border: @base-400;

@btn-default-ghost-color: @base-800;
@btn-default-ghost-bg: @base-400;
@btn-default-ghost-border: @base-300;

// Timeline
// ---
@timeline-color: @base-500;
@timeline-item-padding-bottom: 12px;

// Table
// --
@table-body-sort-bg: @base-800;
@table-expanded-row-bg: @base-800;
@table-header-cell-split-color: @base-500;
