// Colors string
$base-900-string: 210, 17%, 98%;
$base-800-string: 210, 16%, 93%;
$base-700-string: 210, 14%, 89%;
$base-600-string: 210, 16%, 85%;
$base-500-string: 210, 14%, 77%;
$base-400-string: 210, 11%, 68%;
$base-300-string: 210, 8%, 56%;
$base-200-string: 210, 7%, 46%;
$base-100-string: 210, 9%, 31%;
$accent-900-string: 22, 42%, 91%;
$accent-800-string: 21, 43%, 85%;
$accent-700-string: 22, 43%, 77%;
$accent-600-string: 22, 44%, 71%;
$accent-500-string: 22, 48%, 64%;
$accent-400-string: 22, 54%, 61%;
$accent-300-string: 22, 46%, 56%;
$accent-200-string: 22, 38%, 48%;
$accent-100-string: 22, 37%, 37%;
$warning-800-string: 52, 45%, 82%;
$warning-300-string: 52, 52%, 49%;
$error-800-string: 352, 51%, 85%;
$error-300-string: 352, 57%, 64%;
$error-100-string: 352, 41%, 31%;
$success-800-string: 142, 45%, 87%;
$success-500-string: 142, 37%, 68%;
$success-300-string: 142, 38%, 55%;
$success-100-string: 142, 15%, 25%;

// Colors
$base-900: hsl(var(--base-900-string));
$base-800: hsl(var(--base-800-string));
$base-700: hsl(var(--base-700-string));
$base-600: hsl(var(--base-600-string));
$base-500: hsl(var(--base-500-string));
$base-400: hsl(var(--base-400-string));
$base-300: hsl(var(--base-300-string));
$base-200: hsl(var(--base-200-string));
$base-100: hsl(var(--base-100-string));
$accent-900: hsl(var(--accent-900-string));
$accent-800: hsl(var(--accent-800-string));
$accent-700: hsl(var(--accent-700-string));
$accent-600: hsl(var(--accent-600-string));
$accent-500: hsl(var(--accent-500-string));
$accent-400: hsl(var(--accent-400-string));
$accent-300: hsl(var(--accent-300-string));
$accent-200: hsl(var(--accent-200-string));
$accent-100: hsl(var(--accent-100-string));
$warning-800: hsl(var(--warning-800-string));
$warning-300: hsl(var(--warning-300-string));
$error-800: hsl(var(--error-800-string));
$error-300: hsl(var(--error-300-string));
$error-100: hsl(var(--error-100-string));
$success-800: hsl(var(--success-800-string));
$success-500: hsl(var(--success-500-string));
$success-300: hsl(var(--success-300-string));
$success-100: hsl(var(--success-100-string));

// Constants
$page-padding-horizontal: 12px;
$page-padding-vertical: 16px;
$border-size: 1px;
$card-padding: 10px;
$standard-card-height: 140px;
$standard-card-width: 320px;
$blur: 6px;
$strong-blur: 12px;
$brightness: 110%;
$saturation: 120%;
$transition-in-seconds: 200ms;
$transition-out-seconds: 300ms;
$transition-in-slow-seconds: 2 * $transition-in-seconds;
$transition-out-slow-seconds: 2 * $transition-out-seconds;
$letter-spacing-text: -0.2px;
$letter-spacing-title: -0.4px;
$cubic-bezier: cubic-bezier(0.3, 0.3, 0.45, 1);

:root {
  // Colors string
  --base-900-string: #{$base-900-string};
  --base-800-string: #{$base-800-string};
  --base-700-string: #{$base-700-string};
  --base-600-string: #{$base-600-string};
  --base-500-string: #{$base-500-string};
  --base-400-string: #{$base-400-string};
  --base-300-string: #{$base-300-string};
  --base-200-string: #{$base-200-string};
  --base-100-string: #{$base-100-string};
  --accent-900-string: #{$accent-900-string};
  --accent-800-string: #{$accent-800-string};
  --accent-700-string: #{$accent-700-string};
  --accent-600-string: #{$accent-600-string};
  --accent-500-string: #{$accent-500-string};
  --accent-400-string: #{$accent-400-string};
  --accent-300-string: #{$accent-300-string};
  --accent-200-string: #{$accent-200-string};
  --accent-100-string: #{$accent-100-string};
  --warning-800-string: #{$warning-800-string};
  --warning-300-string: #{$warning-300-string};
  --error-800-string: #{$error-800-string};
  --error-300-string: #{$error-300-string};
  --error-100-string: #{$error-100-string};
  --success-800-string: #{$success-800-string};
  --success-500-string: #{$success-500-string};
  --success-300-string: #{$success-300-string};
  --success-100-string: #{$success-100-string};

  // Colors
  --base-900: #{$base-900};
  --base-800: #{$base-800};
  --base-700: #{$base-700};
  --base-600: #{$base-600};
  --base-500: #{$base-500};
  --base-400: #{$base-400};
  --base-300: #{$base-300};
  --base-200: #{$base-200};
  --base-100: #{$base-100};
  --accent-900: #{$accent-900};
  --accent-800: #{$accent-800};
  --accent-700: #{$accent-700};
  --accent-600: #{$accent-600};
  --accent-500: #{$accent-500};
  --accent-400: #{$accent-400};
  --accent-300: #{$accent-300};
  --accent-200: #{$accent-200};
  --accent-100: #{$accent-100};
  --warning-800: #{$warning-800};
  --warning-300: #{$warning-300};
  --error-800: #{$error-800};
  --error-300: #{$error-300};
  --error-100: #{$error-100};
  --success-800: #{$success-800};
  --success-500: #{$success-500};
  --success-300: #{$success-300};
  --success-100: #{$success-100};

  // Constants
  --page-padding-horizontal: #{$page-padding-horizontal};
  --page-padding-vertical: #{$page-padding-vertical};
  --border-size: #{$border-size};
  --card-padding: #{$card-padding};
  --standard-card-height: #{$standard-card-height};
  --standard-card-width: #{$standard-card-width};
  --transition-in-seconds: #{$transition-in-seconds};
  --transition-out-seconds: #{$transition-out-seconds};
  --transition-in-slow-seconds: #{$transition-in-slow-seconds};
  --transition-out-slow-seconds: #{$transition-out-slow-seconds};
  --letter-spacing-text: #{$letter-spacing-text};
  --letter-spacing-title: #{$letter-spacing-title};
  --cubic-bezier: #{$cubic-bezier};
  --blur: #{$blur};
  --strong-blur: #{$strong-blur};
}
